import React from "react";
import Container from "../Container/Container";
import "../../Styles/FooterStyles/FooterAnimationStyle.css";
import { COLORS } from "../../Styles/GlobalStyles";

const Footer = () => {
  const navigatePlayStore = () => {
    const url =
      "https://play.google.com/store/apps/details?id=com.mcvetanovska.CityBusBitola&hl=en";

    window.open(url, "_blank");
  };

  const navigateAppStore = () => {
    const url = "https://www.google.com/";

    window.open(url, "_blank");
  };

  return (
    <div
      style={{
        backgroundColor: COLORS.cardBackgroundColorLight,
        margin: "30px 0px",
      }}>
      <Container>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center mb-2">
            <div>
              <h5 className="text-center">Достапни мобилни апликации</h5>
              <div className="d-flex justify-content-center">
                <div
                  onClick={(
                    event: React.MouseEvent<HTMLDivElement, MouseEvent>
                  ) => {
                    event.preventDefault();
                    navigatePlayStore();
                  }}>
                  <img
                    src="/images/playStoreLogo.png"
                    alt=""
                    style={{ width: 150, height: 50, cursor: "pointer" }}
                  />
                </div>
                <div
                  onClick={(
                    event: React.MouseEvent<HTMLDivElement, MouseEvent>
                  ) => {
                    event.preventDefault();
                    navigateAppStore();
                  }}>
                  <img
                    src="/images/appStoreLogo.png"
                    alt=""
                    style={{ width: 150, height: 48, cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center flex-column">
            <h6>&copy; Донација од Ротари клуб Битола</h6>
            <h6>Изработено од Inellipse - МАБЈ 2024</h6>
          </div>
          <div className="col-12 d-flex justify-content-center text-center text-muted">
            <small>
              Не одговараме за точноста на податоците. Сите податоци што ги
              користиме се превземени од Општина Битола.
            </small>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
