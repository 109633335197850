import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  BusLineDetails,
  BusLines,
  BusStopDetails,
  BusStops,
  HomePage2ActiveBus,
  MessagesAboutUs,
  OperatingHours,
} from "../../Types/Types";
import { LocationContext } from "../LocationContext/LocationContext";

interface AppContextType {
  busStops: BusStops[];
  getBusStopDetailsById: (id: string) => void;
  busStopDetails: BusStopDetails | null;
  resetBusStopDetails: () => void;
  busLines: BusLines[];
  fetchBusLineDetailsById: (id: string) => void;
  busLineDetails: BusLineDetails | null;
  resetBusLineDetails: () => void;
  fetchingOperatingHoursByLineID: (id: string) => void;
  operatingHoursForEachLine: OperatingHours | null;
  resetOperatinghouts: () => void;
  operatinhHoursModal: boolean;
  openOperatinghoursModal: () => void;
  closeOperatinghoursModal: () => void;
  fullScheduledTimeModal: boolean;
  openScheduledTimeModal: () => void;
  closeScheduledTimeModal: () => void;
  messagesAboutUs: MessagesAboutUs | undefined;
  handleSearchBar: () => void;
  showHideSearchBar: boolean;
  handleSearchValue: (text: string) => void;
  searchValue: string;
  resetSearchValue: () => void;
  activeBusHP: HomePage2ActiveBus[];
  onFormSubmit: (selectValue: string, searchValue: string) => void;
  handleSelectValue: (selectedValue: string) => void;
  selectValue: string;
  fetchActiveBuses: () => void;
}

export const AppContext = createContext<AppContextType>({
  busStops: [],
  getBusStopDetailsById: () => {},
  busStopDetails: null,
  resetBusStopDetails: () => {},
  busLines: [],
  fetchBusLineDetailsById: () => {},
  busLineDetails: null,
  resetBusLineDetails: () => {},
  fetchingOperatingHoursByLineID: () => {},
  operatingHoursForEachLine: null,
  resetOperatinghouts: () => {},
  operatinhHoursModal: false,
  openOperatinghoursModal: () => {},
  closeOperatinghoursModal: () => {},
  fullScheduledTimeModal: false,
  openScheduledTimeModal: () => {},
  closeScheduledTimeModal: () => {},
  messagesAboutUs: undefined,
  handleSearchBar: () => {},
  showHideSearchBar: false,
  handleSearchValue: () => {},
  searchValue: "",
  resetSearchValue: () => {},
  activeBusHP: [],
  onFormSubmit: (selectValue: string, searchValue: string) => {},
  handleSelectValue: (selectedValue: string) => {},
  selectValue: "",
  fetchActiveBuses: () => {},
});

interface Props {
  children: React.ReactNode;
}

const part1 = process.env.REACT_APP_1;
const part2 = process.env.REACT_APP_2;
const part3 = process.env.REACT_APP_3;
const part4 = process.env.REACT_APP_4;
const part5 = process.env.REACT_APP_5;
const part6 = process.env.REACT_APP_6;
const part7 = process.env.REACT_APP_SAKAS_KOMPIRI;
const part8 = process.env.REACT_APP_SECURITY_KEY;

const part9 = process.env.REACT_APP_Z;
const part10 = process.env.REACT_APP_Y;
const part11 = process.env.REACT_APP_X;
const part12 = process.env.REACT_APP_W;

const api = `${part1}://${part2}-${part3}-${part4}.${part5}.${part6}/`;

let sakasKompiri = `${part9}${part10}${part11}${part12}`;
sakasKompiri = sakasKompiri.replace(/"/g, "");

const AppContextConstructor: React.FC<Props> = ({ children }) => {
  const [busStops, setBusStops] = useState<BusStops[]>([]);
  const [busStopDetails, setBusStopDetails] = useState<BusStopDetails | null>(
    null
  );
  const [busLines, setBusLines] = useState<BusLines[]>([]);
  const [busLineDetails, setBusLineDetails] = useState<BusLineDetails | null>(
    null
  );
  const [operatingHoursForEachLine, setOperatingHoursForEachLine] =
    useState<OperatingHours | null>(null);
  const [operatinhHoursModal, setOperatinhHoursModal] =
    useState<boolean>(false);
  const [fullScheduledTimeModal, setFullScheduledTimeModal] =
    useState<boolean>(false);
  const [messagesAboutUs, setMessagesAboutUs] = useState<
    MessagesAboutUs | undefined
  >(undefined);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectValue, setSelectValue] = useState<string>("");
  const [showHideSearchBar, setShowHideSearchBar] = useState<boolean>(false);
  const [activeBusHP, setActiveBusHP] = useState<HomePage2ActiveBus[]>([]);

  const { location } = useContext(LocationContext);

  useEffect(() => {
    fetchActiveBuses();
  }, []);

  const fetchActiveBuses = async () => {
    try {
      const response = await fetch(`${api}activeBuses`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "secret-header": sakasKompiri || "",
        },
      });
      const data: HomePage2ActiveBus[] = await response.json();
      setActiveBusHP(data);
    } catch (error) {
      console.error("Error fetching active buses:", error);
    }
  };

  useEffect(() => {
    const lat = location?.latitude;
    const lon = location?.longitude;

    if (location && searchValue && selectValue) {
      fetch(
        `${api}busStop/search?name=${searchValue}&lat=${lat}&lon=${lon}&neighbourhood=${selectValue}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "secret-header": sakasKompiri || "",
          },
        }
      )
        .then((res) => res.json())
        .then((data: BusStops[]) => {
          setBusStops(data);
        });
    } else if (location && searchValue) {
      fetch(`${api}busStop/search?name=${searchValue}&lat=${lat}&lon=${lon}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "secret-header": sakasKompiri || "",
        },
      })
        .then((res) => res.json())
        .then((data: BusStops[]) => {
          setBusStops(data);
        });
    } else if (location && selectValue) {
      fetch(
        `${api}busStop/search?neighbourhood=${selectValue}&lat=${lat}&lon=${lon}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "secret-header": sakasKompiri || "",
          },
        }
      )
        .then((res) => res.json())
        .then((data: BusStops[]) => {
          setBusStops(data);
        });
    } else if (searchValue && selectValue) {
      fetch(
        `${api}busStop/search?name=${searchValue}&neighbourhood=${selectValue}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "secret-header": sakasKompiri || "",
          },
        }
      )
        .then((res) => res.json())
        .then((data: BusStops[]) => {
          setBusStops(data);
        });
    } else if (selectValue) {
      fetch(`${api}busStop/search?neighbourhood=${selectValue}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "secret-header": sakasKompiri || "",
        },
      })
        .then((res) => res.json())
        .then((data: BusStops[]) => {
          setBusStops(data);
        });
    } else if (searchValue) {
      fetch(`${api}busStop/search?name=${searchValue}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "secret-header": sakasKompiri || "",
        },
      })
        .then((res) => res.json())
        .then((data: BusStops[]) => {
          setBusStops(data);
        });
    } else if (location) {
      fetch(`${api}busStop/search?lat=${lat}&lon=${lon}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "secret-header": sakasKompiri || "",
        },
      })
        .then((res) => res.json())
        .then((data: BusStops[]) => {
          setBusStops(data);
        });
    } else {
      fetch(`${api}busStop/search?`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "secret-header": sakasKompiri || "",
        },
      })
        .then((res) => res.json())
        .then((data: BusStops[]) => {
          setBusStops(data);
        });
    }
  }, [location, searchValue, selectValue]);

  useEffect(() => {
    fetch(`${api}busLines/bus-lines`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "secret-header": sakasKompiri || "",
      },
    })
      .then((res) => res.json())
      .then((data: BusLines[]) => {
        setBusLines(data);
      });
  }, []);

  useEffect(() => {
    fetch(`${api}messages`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "secret-header": sakasKompiri || "",
      },
    })
      .then((res) => res.json())
      .then((data: MessagesAboutUs) => {
        setMessagesAboutUs(data);
      });
  }, []);

  const getBusStopDetailsById = useCallback(
    async (id: string) => {
      try {
        const res = await fetch(`${api}busStopDetails/totalTime?stopId=${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "secret-header": sakasKompiri || "",
          },
        });

        const data: BusStopDetails = await res.json();

        if (res.status === 430) {
          setBusStopDetails(data);
        } else if (!res.ok) {
          throw new Error(
            `Network response was not ok: ${res.status} - ${res.statusText}`
          );
        } else {
          setBusStopDetails(data);
        }
      } catch (error) {
        console.error("Failed to fetch bus stop details:", error);
      }
    },
    [setBusStopDetails]
  );

  const fetchBusLineDetailsById = useCallback(
    async (id: string) => {
      try {
        setBusLineDetails(null);

        const res = await fetch(
          `${api}busLineDetails/bus-line-details?lines=${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "secret-header": sakasKompiri || "",
            },
          }
        );

        const data: BusLineDetails[] = await res.json();

        if (res.status === 430) {
          setBusLineDetails(data[0]);
        } else if (!res.ok) {
          throw new Error(
            `Network response was not ok: ${res.status} - ${res.statusText}`
          );
        } else {
          setBusLineDetails(data[0]);
        }
      } catch (error) {
        console.error("Failed to fetch line details:", error);
      }
    },
    [setBusLineDetails]
  );

  const fetchingOperatingHoursByLineID = async (id: string) => {
    fetch(`${api}operatingHours/bus/${id}`)
      .then((res) => res.json())
      .then((data: OperatingHours) => {
        setOperatingHoursForEachLine(data);
      })
      .catch((error) => {
        console.error("Failed to fetch operating hours:", error);
      });
  };

  const resetBusStopDetails = () => {
    setBusStopDetails(null);
  };

  const resetBusLineDetails = () => {
    setBusStopDetails(null);
  };

  const resetOperatinghouts = () => {
    setOperatingHoursForEachLine(null);
  };

  const openOperatinghoursModal = () => {
    setOperatinhHoursModal(true);
  };

  const closeOperatinghoursModal = () => {
    setOperatinhHoursModal(false);
  };

  const openScheduledTimeModal = () => {
    setFullScheduledTimeModal(true);
  };

  const closeScheduledTimeModal = () => {
    setFullScheduledTimeModal(false);
  };

  const handleSearchBar = () => {
    setSearchValue("");
    setShowHideSearchBar(!showHideSearchBar);
  };

  const handleSearchValue = (text: string) => {
    setSearchValue(text);
  };

  const resetSearchValue = () => {
    setSearchValue("");
    setShowHideSearchBar(false);
  };

  const onFormSubmit = (selectValue: string, searchValue: string) => {
    setSelectValue(selectValue);
    setSearchValue(searchValue);
  };

  const handleSelectValue = (selectedValue: string) => {
    setSelectValue(selectedValue);
  };

  return (
    <AppContext.Provider
      value={{
        busStops,
        getBusStopDetailsById,
        busStopDetails,
        resetBusStopDetails,
        busLines,
        fetchBusLineDetailsById,
        busLineDetails,
        resetBusLineDetails,
        fetchingOperatingHoursByLineID,
        operatingHoursForEachLine,
        resetOperatinghouts,
        openOperatinghoursModal,
        closeOperatinghoursModal,
        operatinhHoursModal,
        fullScheduledTimeModal,
        openScheduledTimeModal,
        closeScheduledTimeModal,
        messagesAboutUs,
        handleSearchBar,
        showHideSearchBar,
        handleSearchValue,
        searchValue,
        resetSearchValue,
        activeBusHP,
        onFormSubmit,
        handleSelectValue,
        selectValue,
        fetchActiveBuses,
      }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContextConstructor;
