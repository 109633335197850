export const COLORS = {
  lightPink: "#F3F3F3",
  goldenYellow: "#F1C376",
  mossGreen: "#0F9565",
  darkBackgroundColor: "#0B0607",
  lightBackgroundColor: "#F3F3F3",
  cardBackgroundColorLight: "#FCFCFD",
  cardBackgroundColorDark: "#282828",
  timeBoxColorLight: "#D9D9D9",
  busStationTitleBackgroundLight: "#C6C6C64D",
};

export const PADDINGS = {
  padding5: 5,
  padding10: 10,
  padding15: 15,
  padding20: 20,
  padding25: 25,
};

export const MARGINS = {
  margin5: 5,
  margin10: 10,
  margin15: 15,
  margin20: 20,
  margin25: 25,
};

export const BORDERRADIUS = {
  borderRadius5: 5,
  borderRadius10: 10,
  borderRadius15: 15,
  borderRadius20: 20,
  borderRadius27: 27,
};
