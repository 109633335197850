import React, { useContext } from "react";
import "../../Styles/AboutUsStyles/AboutUsStyles.css";
import { AppContext } from "../../Context/AppContext/AppContext";
import { COLORS } from "../../Styles/GlobalStyles";
import LoadingPage from "../../Components/LoadingPage/LoadingPage";

const AboutUs = () => {
  const { messagesAboutUs } = useContext(AppContext);

  const images = [
    {
      title: "Ротари Клуб Битола",
      imageSource: "/images/img/rotaryLogo.png",
      marginBottom: 12,
    },
    {
      title: "Општина Битола",
      imageSource: "/images/img/grbOpstinaBitola3.png",
      marginBottom: 0,
    },
    {
      title: "Inellipse",
      imageSource: "/images/img/inellipseLogo1.png",
      marginBottom: 8,
    },
  ];

  const renderGreen = () => {
    if (messagesAboutUs?.title3) {
      const title = messagesAboutUs.title3;
      const greenWord = "зелена";

      if (title.includes("За зелена Битола!")) {
        const parts = title.split(greenWord);

        return (
          <>
            {parts[0]}
            <span style={{ color: COLORS.mossGreen }}>{greenWord}</span>
            {parts[1]}
          </>
        );
      }

      return <>{title}</>;
    }
  };

  if (!messagesAboutUs) {
    return <LoadingPage />;
  }

  return (
    <div className="aboutUsContainer">
      <div
        className="headerAboutUs"
        style={{ backgroundColor: COLORS.busStationTitleBackgroundLight }}>
        <p>{messagesAboutUs?.title1}</p>
      </div>

      <div className="mainContent">
        <div
          className="leftSiteAboutUs"
          style={{ backgroundColor: COLORS.mossGreen }}>
          <p>{messagesAboutUs?.title2}</p>
        </div>

        <div
          className="rightSiteAboutUs"
          style={{ backgroundColor: COLORS.mossGreen }}>
          <p>{messagesAboutUs?.title4}</p>
        </div>
      </div>

      <div className="logosAboutUs">
        {images.map((img, index) => {
          return (
            <div
              className="imageContainerAboutUs"
              key={`${img.title}-${index}`}>
              <img
                className="imgAboutUs"
                src={img.imageSource}
                style={{
                  marginBottom: img.marginBottom,
                }}
                alt=""
              />
              <p className="imageDescriptionAboutUs">{img.title}</p>
            </div>
          );
        })}
      </div>

      <div
        className="headerAboutUs"
        style={{ backgroundColor: COLORS.busStationTitleBackgroundLight }}>
        <p>{renderGreen()}</p>
      </div>
    </div>
  );
};

export default AboutUs;
