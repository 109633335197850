import React, { useContext, useState } from "react";
import { COLORS } from "../../Styles/GlobalStyles";
import { AppContext } from "../../Context/AppContext/AppContext";
import { options } from "../../Utils/UtilityFunctions";

const FormComponent = () => {
  const {
    onFormSubmit,
    handleSelectValue,
    handleSearchValue,
    searchValue,
    selectValue,
  } = useContext(AppContext);

  const [search, setSearch] = useState<string>("");
  const [select, setSelect] = useState<string>("");

  const scrollWhenSearch = (): void => {
    window.scrollTo({
      top: 110,
      behavior: "smooth",
    });
  };

  return (
    <div className="mb-3">
      <div className="row">
        <div className="col-12 d-flex justify-content-center align-items-center">
          <h6>
            Одберете населба или пребарајте за да ги видите постојките по
            населби
          </h6>
        </div>
      </div>
      <form
        action=""
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          //   onFormSubmit(select, search);
        }}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mb-3 d-flex justify-content-center">
            {/* <label htmlFor="neighborhoodSelect">Изберете населба</label> */}
            <select
              className="form-select"
              id="neighborhoodSelect"
              value={selectValue}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                event.preventDefault();
                const selectedValue = event.target.value;
                handleSelectValue(selectedValue);
                // setSelect(selectedValue);
              }}>
              {options.map((item, index) => {
                return (
                  <option value={item.value} key={`${item.name}-${index}`}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 md-m-0 mb-3 d-flex justify-content-center">
            <input
              id="search"
              type="text"
              className="form-control"
              placeholder="Пребарајте постојки..."
              value={searchValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleSearchValue(event.target.value);
                //   setSearch(event.target.value);
              }}
              onClick={(
                event: React.MouseEvent<HTMLInputElement, MouseEvent>
              ) => {
                event.preventDefault();
                if (window.innerWidth <= 768) {
                  scrollWhenSearch();
                }
              }}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormComponent;
