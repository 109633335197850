import React, { useEffect, useState } from "react";
import { createContext } from "react";

interface Location {
  latitude: number;
  longitude: number;
}

interface LocationContextType {
  location: Location | null;
}

export const LocationContext = createContext<LocationContextType>({
  location: null,
});

interface Props {
  children: React.ReactNode;
}

const LocationContextConstructor: React.FC<Props> = ({ children }) => {
  const [location, setLocation] = useState<Location | null>(null);
  const [error, setError] = useState<string | null>(null);

  //   const getLocation = () => {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(
  //         (position) => {
  //           const { latitude, longitude } = position.coords;
  //           setLocation({ latitude, longitude });
  //           setError(null);
  //         },
  //         (err) => {
  //           setError("Unable to retrieve your location");
  //           console.error(err);
  //         }
  //       );
  //     } else {
  //       setError("Geolocation is not supported by this browser.");
  //     }
  //   };

  //   useEffect(() => {
  //     getLocation();
  //   }, []);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          setError(null);
        },
        (err) => {
          if (err.code === 1) {
            setError("User denied Geolocation. Please enable location access.");
          } else {
            setError("Unable to retrieve your location");
          }
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  const requestLocation = () => {
    getLocation();
  };

  useEffect(() => {
    requestLocation();
  }, []);

  return (
    <LocationContext.Provider value={{ location }}>
      {children}
    </LocationContext.Provider>
  );
};

export default LocationContextConstructor;
